<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>
            {{ $t("event_registrierung.einstieg.label_veranstalter_werden") }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="p-4 bg-mellow">
        <b-col cols="12" class="pb-4">
          <h1 class="h3">{{ $t("event_registrierung.passwort_reset.page_title") }}</h1>
        </b-col>
        <b-col cols="12">
          <LoginReset />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import LoginReset from "@/views/Formulare/LoginReset";

export default {
  components: {
    LoginReset,
  },
  data() {
    return {
      loginKey: 1, // Key wird verwendet um beim Component Login einen Reload zu forcieren (Änderung des Keys forciert reload)
    };
  },
};
</script>
